<template>
    <calc-result-page>
      <div class="center">
        <div class="center-title">{{item.desc}}</div>
        <div class="money-box">{{item.damage}}<span>元</span></div>

        <div class="button-box">以上结果只是测算，最终以医保局计算结果为准</div>
      </div>
    </calc-result-page>
</template>

<script>
import CalcResultPage from '@/components/CalcResultPage'
export default {
  components: {
    CalcResultPage
  },
  data() {
    return {
      item: {}
    }
  },
  mounted() {
    const res = this.$route.query.res
    if (res !== undefined) {
      this.item = JSON.parse(this.$route.query.res);
    }
  }
}
</script>

<style scoped>
.center {
  padding: 0 20px 40px 14px;
}

.money-box {
  font-size: 44px;
  font-weight: bold;
  line-height: 54px;
  color: #658CF1;
}

.money-box span {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #999999;
}

.center-title {
  width: 100%;
  font-size: 24px;
  line-height: 33px;
  color: #4D4D4D;
  text-align: left;
  margin-bottom: 12px;
}

.button-box {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #658CF1;
  margin: 24px 0 0 16px;
}


</style>